import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-959349a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_BrandFormModels = _resolveComponent("BrandFormModels")!
  const _component_BrandFormGenerations = _resolveComponent("BrandFormGenerations")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tabId": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading,
    tabs: _ctx.isContentLoading ? [] : _ctx.tabList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading && _ctx.brand)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'general' ? 'block' : 'none' })
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FieldValue, {
                  label: "ID",
                  value: String(_ctx.brand.id)
                }, null, 8, ["value"]),
                _createVNode(_component_FieldValue, {
                  label: "API ID",
                  value: String(_ctx.brand.apiId)
                }, null, 8, ["value"]),
                _createVNode(_component_FieldValue, {
                  label: "Имя",
                  value: _ctx.brand.name
                }, null, 8, ["value"])
              ]),
              _createVNode(_component_FieldValue, {
                label: "Дилеры",
                type: "list",
                value: _ctx.brand.dealers
              }, null, 8, ["value"]),
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.showInCatalog,
                "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.showInCatalog) = $event)),
                name: "showInCatalog",
                label: "Показывать в каталоге",
                error: _ctx.errors.showInCatalog
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.seoRegionalDisabled,
                "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.seoRegionalDisabled) = $event)),
                name: "seoRegionalDisabled",
                label: "Не отображать город в SEO параметрах",
                error: _ctx.errors.seoRegionalDisabled
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.displayName,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.displayName) = $event)),
                name: "displayName",
                label: "Отображаемое имя",
                error: _ctx.errors.displayName
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.priority,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.priority) = $event)),
                name: "priority",
                label: "Приоритет",
                error: _ctx.errors.priority
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldSelect, {
                value: _ctx.values.kufarId,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.kufarId) = $event)),
                options: _ctx.kufarOptions,
                label: "Kufar",
                error: _ctx.errors.kufarId
              }, null, 8, ["value", "options", "error"])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'web' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FieldValue, {
                label: "Веб-страница",
                type: "link",
                src: _ctx.model.catalogUrl,
                value: _ctx.model.catalogUrl
              }, null, 8, ["src", "value"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.logo,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.logo) = $event)),
                error: _ctx.errors.logo,
                label: "Логотип (540 x 270)",
                name: "logoFile",
                "file-type": "image",
                scenario: "logo-s3"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.logoLanding,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.logoLanding) = $event)),
                error: _ctx.errors.logoLanding,
                label: "Логотип на лендинге (780 x 440)",
                name: "logoLandingFile",
                "file-type": "image",
                scenario: "logo-s3"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.cover,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.cover) = $event)),
                error: _ctx.errors.coverFile,
                label: "Обложка (1360 x 360)",
                name: "coverFile",
                "file-type": "image",
                scenario: "cover-s3"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.coverMobile,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.coverMobile) = $event)),
                error: _ctx.errors.coverFile,
                label: "Обложка - Телефон (440 x 352)",
                name: "coverMobile",
                "file-type": "image",
                scenario: "cover-s3"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.title,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.values.title) = $event)),
                name: "title",
                label: "Заголовок",
                error: _ctx.errors.title
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.textShort,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.values.textShort) = $event)),
                name: "textShort",
                label: "Текст - Краткий",
                error: _ctx.errors.textShort,
                type: "textarea"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.textFull,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.values.textFull) = $event)),
                name: "textFull",
                label: "Текст - Полный",
                error: _ctx.errors.textFull,
                type: "textarea"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.warrantyText,
                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.values.warrantyText) = $event)),
                name: "warrantyText",
                label: "Гарантия - Текст",
                error: _ctx.errors.warrantyText
              }, null, 8, ["value", "error"])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'models' ? 'block' : 'none' })
            }, [
              (_ctx.model)
                ? (_openBlock(), _createBlock(_component_BrandFormModels, {
                    key: 0,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true)
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'generations' ? 'block' : 'none' })
            }, [
              (_ctx.model)
                ? (_openBlock(), _createBlock(_component_BrandFormGenerations, {
                    key: 0,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true)
            ], 4)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}