import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { ComplectationFullInterface } from "@/typings/model";
import {
  ComplectationSavePayload,
  ComplectationCustomSavePayload,
} from "@/services/requests";

export interface FormValues {
  name: string;
  subtitle: string;
  badge: string | null;
  image: Nullable<SingleFileInputValueType>;
  engines: Array<OptionType<number>>;

  doorsCount: number | null;
  seatsCount: number | null;
  trunkVolume: number | null;
  maxTrunkVolume: number | null;
  width: number | null;
  length: number | null;
  height: number | null;
  clearance: number | null;
  minWeight: number | null;
  maxWeight: number | null;
}

export function getComplectationFormValues(
  model: Nullable<ComplectationFullInterface>,
  engines: Array<OptionType<number>> = []
): FormValues {
  if (!model) {
    return {
      name: "",
      subtitle: "",
      badge: "",
      image: null,
      engines: [],
      doorsCount: null,
      seatsCount: null,
      trunkVolume: null,
      maxTrunkVolume: null,
      width: null,
      length: null,
      height: null,
      clearance: null,
      minWeight: null,
      maxWeight: null,
    };
  }

  const engineIds = model.engines?.map((item) => +item.id) || [];

  return {
    name: model.name,
    subtitle: model.subtitle,
    badge: model.badge,
    image: model?.image ? { id: createId(), file: model.image } : null,
    engines: engines.filter((item) => engineIds.includes(item.value)),

    doorsCount: model.params.doorsCount,
    seatsCount: model.params.seatsCount,
    trunkVolume: model.params.trunkVolume,
    maxTrunkVolume: model.params.maxTrunkVolume,
    width: model.params.width,
    length: model.params.length,
    height: model.params.height,
    clearance: model.params.clearance,
    minWeight: model.params.minWeight,
    maxWeight: model.params.maxWeight,
  };
}

export function convertFormValuesToSavePayload(
  values: FormValues
): ComplectationSavePayload {
  return {
    subtitle: values.subtitle,
    badge: values.badge,
    image: values.image?.file?.id || null,
    engines: values.engines.map((item) => item.value),

    doorsCount: values.doorsCount,
    seatsCount: values.seatsCount,
    trunkVolume: values.trunkVolume,
    maxTrunkVolume: values.maxTrunkVolume,
    width: values.width,
    length: values.length,
    height: values.height,
    clearance: values.clearance,
    minWeight: values.minWeight,
    maxWeight: values.maxWeight,
  };
}

export function convertFormValuesToCustomSavePayload(
  values: FormValues
): ComplectationCustomSavePayload {
  return {
    generation: null,
    name: values.name,
    subtitle: values.subtitle,
    badge: values.badge,
    image: values.image?.file?.id || null,
    engines: values.engines.map((item) => item.value),

    doorsCount: values.doorsCount,
    seatsCount: values.seatsCount,
    trunkVolume: values.trunkVolume,
    maxTrunkVolume: values.maxTrunkVolume,
    width: values.width,
    length: values.length,
    height: values.height,
    clearance: values.clearance,
    minWeight: values.minWeight,
    maxWeight: values.maxWeight,
  };
}
