import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { ParamType, ParamTypeOptions } from "@/modules/params/enums";
import { ParamDetailedModel, ParamSavePayload } from "@/modules/params/typings";

export interface ParamFormValues {
  category: Nullable<OptionType<number>>;
  name: string;
  hint: string;
  type: ParamType;
  options: string;
}

export function getParamFormValues(
  model: Nullable<ParamDetailedModel>,
  categoryOptions: Array<OptionType<number>> = [],
  defaultCategoryId?: number | null
): ParamFormValues {
  return {
    category:
      categoryOptions.find(
        (item) =>
          item.value ===
          (model?.category.id ? model?.category.id : defaultCategoryId)
      ) || null,
    name: model?.name || "",
    hint: model?.hint || "",
    type:
      ParamTypeOptions.find((item) => item.value === model?.type)?.value ||
      ParamTypeOptions[0].value,
    options: model?.options ? model.options.join("\n") : "",
  };
}

export function convertParamFormValuesToSavePayload(
  values: ParamFormValues,
  optionsField: RepeaterField
): ParamSavePayload {
  return {
    category: values.category?.value || null,
    name: values.name,
    hint: values.hint,
    type: values.type || null,
    options: optionsField.value.map((row) => {
      return {
        option: String(row.value[0]?.value),
        weight: row.value[1]?.value ? Number(row.value[1]?.value) : null,
      };
    }),
  };
}

export const PARAMS_OPTIONS_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Опции",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "option",
      type: "STRING",
      label: "Опция",
    },
    {
      name: "weight",
      type: "NUMBER",
      label: "Вес",
    },
  ],
};
