import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldOptionsSwitcher = _resolveComponent("FormFieldOptionsSwitcher")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createBlock(_component_FormFooter, {
            key: 0,
            "back-href": _ctx.backButtonUrl,
            "is-submitting": _ctx.isSubmitting,
            "is-creation": _ctx.isCreation,
            "can-create-another": true,
            onSubmit: _ctx.submitForm
          }, null, 8, ["back-href", "is-submitting", "is-creation", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (_ctx.isCreation)
              ? (_openBlock(), _createBlock(_component_FormFieldSelect, {
                  key: 0,
                  value: _ctx.values.category,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.category) = $event)),
                  label: "Категория",
                  options: _ctx.categoryOptions
                }, null, 8, ["value", "options"]))
              : (_openBlock(), _createBlock(_component_FieldValue, {
                  key: 1,
                  label: "Категория",
                  value: _ctx.categoryName
                }, null, 8, ["value"])),
            _createVNode(_component_FormField, {
              value: _ctx.values.name,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.name) = $event)),
              name: "name",
              error: _ctx.errors.name,
              label: "Название"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.hint,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.hint) = $event)),
              name: "hint",
              error: _ctx.errors.hint,
              label: "Подсказка",
              type: "textarea"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormFieldOptionsSwitcher, {
              value: _ctx.values.type,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.type) = $event)),
              name: "name",
              error: _ctx.errors.type,
              label: "Тип",
              options: _ctx.typeOptions
            }, null, 8, ["value", "error", "options"]),
            (_ctx.optionsField)
              ? (_openBlock(), _createBlock(_component_DynamicField, {
                  key: 2,
                  field: _ctx.optionsField
                }, null, 8, ["field"]))
              : _createCommentVNode("", true)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}