<template>
  <Page :title="pageTitle" :is-content-loading="isContentLoading">
    <form v-if="!isContentLoading" novalidate @submit.prevent>
      <FormFieldSelect
        v-if="isCreation"
        v-model:value="values.category"
        label="Категория"
        :options="categoryOptions"
      />
      <FieldValue v-else label="Категория" :value="categoryName" />

      <FormField
        v-model:value="values.name"
        name="name"
        :error="errors.name"
        label="Название"
      />
      <FormField
        v-model:value="values.hint"
        name="hint"
        :error="errors.hint"
        label="Подсказка"
        type="textarea"
      />
      <FormFieldOptionsSwitcher
        v-model:value="values.type"
        name="name"
        :error="errors.type"
        label="Тип"
        :options="typeOptions"
      />
      <DynamicField v-if="optionsField" :field="optionsField" />
    </form>
    <template #footer>
      <FormFooter
        v-if="!isContentLoading"
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        :is-creation="isCreation"
        :can-create-another="true"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  FileType,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormField,
  FormFieldSelect,
  FormFooter,
  FormFieldOptionsSwitcher,
  FieldValue,
  TagerFormSubmitEvent,
  OptionType,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import {
  convertParamFormValuesToSavePayload,
  getParamFormValues,
  ParamFormValues,
  PARAMS_OPTIONS_CONFIG,
} from "@/modules/params/ParamsForm/ParamsForm.helpers";
import { createParam, getParam, updateParam } from "@/modules/params/requests";
import { getParamsListUrl, getParamsUpdateUrl } from "@/modules/params/paths";
import { ParamType, ParamTypeOptions } from "@/modules/params/enums";
import { getParamsCategoriesList } from "@/modules/params-categories/requests";

export default defineComponent({
  name: "ParamsForm",
  components: {
    FormField,
    FormFieldSelect,
    FormFieldOptionsSwitcher,
    Page,
    FormFooter,
    FieldValue,
    DynamicField,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const isSubmitting = ref<boolean>(false);
    const errors = ref<Record<string, string>>({});

    const id = computed(() => route.params.id as string | undefined);
    const isCreation = computed(() => id.value === "create");

    const values = ref<ParamFormValues>(getParamFormValues(null));

    const [
      fetchCategories,
      { data: categories, loading: isCategoriesLoading },
    ] = useResource({
      fetchResource: () => getParamsCategoriesList(),
      initialValue: null,
      resourceName: "Params Categories",
    });

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getParam(+id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Param",
    });

    onMounted(() => {
      fetchModel();
      fetchCategories();
    });

    const categoryOptions = computed<Array<OptionType<number>>>(
      () =>
        categories.value?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
    );

    watch(id, fetchModel);
    watch(
      [model, categoryOptions],
      () =>
        (values.value = getParamFormValues(
          model.value,
          categoryOptions.value,
          route.query.category ? +route.query.category : null
        ))
    );

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const payload = convertParamFormValuesToSavePayload(
        values.value,
        optionsField.value as RepeaterField
      );

      const requestPromise = isCreation.value
        ? createParam(payload)
        : updateParam(parseInt(id?.value || ""), payload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getParamsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, backButtonUrl.value);
          }

          if (event.type === "create_create-another") {
            values.value = getParamFormValues(
              null,
              categoryOptions.value,
              route.query.category ? +route.query.category : null
            );
          }

          toast.show({
            variant: "success",
            title: "Категории",
            body: isCreation.value
              ? "Параметр успешно создан"
              : "Параметр успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Категории",
            body: isCreation.value
              ? "Ошибка добавления параметра"
              : "Ошибка изменения параметра",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () => Boolean(isModelLoading.value) || Boolean(isCategoriesLoading.value)
    );

    const pageTitle = computed<string>(() => {
      if (isContentLoading.value) {
        return "Загрузка...";
      } else if (isCreation.value) {
        return `Новый параметр`;
      } else {
        return `Редактирование параметра # ${model.value?.id}`;
      }
    });

    const backButtonUrl = computed<string>(() =>
      getParamsListUrl(values.value.category?.value || 0)
    );

    const categoryName = computed<string>(() => {
      return (
        categories.value?.find(
          (item) => item.id === values.value.category?.value
        )?.name || ""
      );
    });

    const optionsField = ref<FieldUnion>();

    function updateOptionsField() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.options
        ? model.value?.options.map((value) => {
            return [
              {
                name: "option",
                value: value.option || "",
              },
              {
                name: "weight",
                value: value.weight || 0,
              },
            ];
          })
        : [];

      optionsField.value = universalFieldUtils.createFormField(
        PARAMS_OPTIONS_CONFIG,
        incomingFieldList
      );
    }

    watch([model], updateOptionsField);
    onMounted(updateOptionsField);

    return {
      submitForm,
      pageTitle,
      isContentLoading,
      model,

      backButtonUrl,
      isSubmitting,
      isCreation,

      errors,
      values,
      typeOptions: ParamTypeOptions,
      categoryOptions,
      categoryName,
      optionsField,
    };
  },
  computed: {
    ParamType() {
      return ParamType;
    },
  },
});
</script>
