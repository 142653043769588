import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4def3797"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumbs" }
const _hoisted_2 = { class: "row-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tabId": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading,
    tabs: _ctx.isContentLoading ? [] : _ctx.tabList
  }, {
    topRight: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.url
          }, [
            _createVNode(_component_RouterLink, {
              to: item.url
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ]),
    footer: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createBlock(_component_FormFooter, {
            key: 0,
            "back-href": _ctx.backButtonUrl,
            "is-submitting": _ctx.isSubmitting,
            "is-creation": _ctx.isCreation,
            "can-create-another": true,
            onSubmit: _ctx.submitForm
          }, null, 8, ["back-href", "is-submitting", "is-creation", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'general' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FieldValue, {
                label: "Поколение",
                value: _ctx.generationName
              }, null, 8, ["value"]),
              (_ctx.isCustom)
                ? (_openBlock(), _createBlock(_component_FormField, {
                    key: 0,
                    value: _ctx.values.name,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
                    name: "name",
                    error: _ctx.errors.name,
                    label: "Название"
                  }, null, 8, ["value", "error"]))
                : (_openBlock(), _createBlock(_component_FieldValue, {
                    key: 1,
                    label: "Название",
                    value: _ctx.values.name
                  }, null, 8, ["value"])),
              _createVNode(_component_FormField, {
                value: _ctx.values.subtitle,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.subtitle) = $event)),
                name: "subtitle",
                error: _ctx.errors.subtitle,
                label: "Подзаголовок"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.badge,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.badge) = $event)),
                name: "badge",
                error: _ctx.errors.badge,
                label: "Бейдж"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.image,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.image) = $event)),
                error: _ctx.errors.image,
                label: "Картинка (245 x 180)",
                name: "image",
                "file-type": "image",
                scenario: "complectation"
              }, null, 8, ["value", "error"])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'params' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FormFieldMultiSelect, {
                "selected-options": _ctx.values.engines,
                "onUpdate:selectedOptions": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.engines) = $event)),
                disabled: _ctx.isEnginesLoading,
                options: _ctx.engineOptions,
                label: "Двигатели",
                searchable: false
              }, null, 8, ["selected-options", "disabled", "options"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.doorsCount,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.doorsCount) = $event)),
                  name: "doorsCount",
                  type: "number",
                  error: _ctx.errors.doorsCount,
                  label: "Количество дверей"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.seatsCount,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.seatsCount) = $event)),
                  name: "seatsCount",
                  type: "number",
                  error: _ctx.errors.seatsCount,
                  label: "Количество мест"
                }, null, 8, ["value", "error"]),
                _createElementVNode("div", null, [
                  _createVNode(_component_FormField, {
                    value: _ctx.values.trunkVolume,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.trunkVolume) = $event)),
                    name: "trunkVolume",
                    type: "number",
                    error: _ctx.errors.trunkVolume,
                    label: "Объем багажника, л."
                  }, null, 8, ["value", "error"]),
                  _createVNode(_component_FormField, {
                    value: _ctx.values.maxTrunkVolume,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.maxTrunkVolume) = $event)),
                    name: "maxTrunkVolume",
                    type: "number",
                    error: _ctx.errors.maxTrunkVolume,
                    label: "Максимальный объем багажника, л."
                  }, null, 8, ["value", "error"])
                ]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.width,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.values.width) = $event)),
                  name: "width",
                  type: "number",
                  error: _ctx.errors.width,
                  label: "Ширина, мм."
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.length,
                  "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.values.length) = $event)),
                  name: "length",
                  type: "number",
                  error: _ctx.errors.length,
                  label: "Длина, мм."
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.height,
                  "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.values.height) = $event)),
                  name: "height",
                  type: "number",
                  error: _ctx.errors.height,
                  label: "Высота, мм."
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.clearance,
                  "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.values.clearance) = $event)),
                  name: "clearance",
                  type: "number",
                  error: _ctx.errors.clearance,
                  label: "Клиренс, мм."
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.minWeight,
                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.values.minWeight) = $event)),
                  name: "minWeight",
                  type: "number",
                  error: _ctx.errors.minWeight,
                  label: "Снаряженная масса, кг."
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.maxWeight,
                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.values.maxWeight) = $event)),
                  name: "maxWeight",
                  type: "number",
                  error: _ctx.errors.maxWeight,
                  label: "Максимально допустимая масса, кг."
                }, null, 8, ["value", "error"])
              ])
            ], 4)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}